import React from 'react'

function Projects() {
  return (
    <div className='d-flex flex-column flex-grow-1 justify-content-center align-items-center'>
      Updating soon...
      
    </div>
  )
}

export default Projects
