import React from 'react';

import vv from '../assets/images/vv-t.png';
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { IoMdDownload } from 'react-icons/io';
import pdf from "../assets/files/VamsiVemulapalliFullStackDeveloper.pdf";

function BannerV2() {

  // const copyClipBoard = async (text) =>{
  //   await navigator.clipboard.writeText(text);
  // }

  return (
    <div className='d-flex flex-row flex-grow-1 justify-content-center justify-content-md-end  banner-bg'>
      <div className='d-flex flex-column flex-grow-1 justify-content-end justify-content-md-center justify-content-lg-center align-items-center align-items-md-end align-items-lg-end vv-content' style={{zIndex:1}}>
        <div className='d-flex flex-column justify-content-center align-items-start vv-animated-content gap-2' >
          <p className='type-animation'>Hello!!!</p>
          <p className='type-animation'>I'm <span><i><b>Vamsi Vemulapalli</b></i></span></p>
          {/* <p className='opacity-animation'>MEAN/MERN Stack Developer</p> */}
          <span className='opacity-animation'>I'm a passionate <b>MEAN/MERN</b> developer dedicated to crafting exceptional user experiences through intuitive and visually stunning interfaces. 
        With a blend of creativity and technical expertise, I specialize in <b>translating ideas into engaging digital products</b> that resonate with users. <Link className='more-info' to={`/home/about-me`}>More Info.</Link> </span>
          <p className='opacity-animation gap-2'><FaPhone />&nbsp;+44 7405364752&nbsp;
            {/* <FaClipboard className='vv-cursor-pinter' /> */}
          </p>
          <p className='opacity-animation gap-2'><MdEmail />&nbsp;vamsi_vemulapalli@outlook.com &nbsp;
            {/* <FaClipboard className='vv-cursor-pinter' /> */}
          </p>
          <a  href={pdf} download="Vamsi Vemulapalli Full Stack Developer"
              className={`d-flex flex-row justify-content-center align-items-center gap-2 button-outline`} >
            <IoMdDownload  />
            <div>Download Resume</div>
          </a>
        </div>
        <div>
          
        </div>
      </div>
      <img className='vv-image' src={vv} alt={`Vamsi Vemulapalli's`}></img>
    </div>
  )
}

export default BannerV2
