import React from 'react'

function Trublu() {
  return (
    <div>
      <trublu-web-component 
          remove-logout="false"
          show-modal="true"
          button-text="Initiate KYC"
          callback={(data) => this.showMessage(data)}
          init-token="eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI2NWFlNTc4NzQ4NTg2OTNkNzUxY2MxYjMiLCJzdWIiOiIzMjM5YzZhNmRhZjA0MzkxOGU3NDU3MzA3ZmUyMDc2NyIsImF1ZCI6IklOSVQiLCJpYXQiOjE3MDY1MjU4NjAwNzAsImp0aSI6IjY1Yjc4NGEzZTg3NTcyZTVhYTI3OTNiYyIsImV4cCI6NjgyNjExNzg0MH0.Ng638oeVSIiYwolRShgarA-zOMTEXA_y7oIOKhUbJS9jAr5ilooPbX-zFgnwfnJN2QWklN1xjGGV3s_BFbD3Jg" >
      </trublu-web-component>
    </div>
  )
}

export default Trublu
