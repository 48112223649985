import React from 'react';
import { BrowserRouter, Routes, Route, Navigate  } from 'react-router-dom';
import AboutMe from './views/AboutMe';
import Projects from './views/Projects';
import Home from './views/Home';
import BannerV2 from './views/BannerV2';
import Trublu from './views/Trublu';

function Routing() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='' element={<Navigate to="/home" />} />
            <Route path='/home' element={<Home />}>
                <Route path='' element={<BannerV2 />} />
                <Route path='about-me' element={<AboutMe />} />
                <Route path='projects' element={<Projects />} />
                <Route path='trublu' element={<Trublu />} />
            </Route>
        </Routes>
    </BrowserRouter>
  )
}

export default Routing
