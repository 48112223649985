import React from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';

function Home() {
  return (
    <div className='d-flex flex-column flex-grow-1 overflow-hidden' >
      <Header />
      <div className='d-flex flex-column flex-grow-1 overflow-auto'>
        <Outlet />
      </div>
      <div className="d-md-none" style={{zIndex:100}}>
        <Footer  />
      </div>
    </div>
  )
}

export default Home
