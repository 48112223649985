import React from 'react';
import { IoHome } from "react-icons/io5";
import { MdWork } from "react-icons/md";
import { RiProfileLine } from "react-icons/ri";
import { IoMdDownload } from "react-icons/io";
import { NavLink, useLocation } from 'react-router-dom';
import pdf from "../assets/files/VamsiVemulapalliFullStackDeveloper.pdf";

function Header() {


  const location = useLocation();

  return (
    <div className='vv-header d-flex flex-row justify-content-between  gap-32'>
      <div className='col d-flex flex-row justify-content-start align-items-center text-decoration-none'>
        <NavLink className={'text-decoration-none'} to={`/home/`}>
          <div className='title vv-cursor-pointer '>
            Vamsi Vemulapalli
          </div>
        </NavLink>
      </div>
      <div className='col d-flex flex-row justify-content-end align-items-center d-none d-md-flex gap-2'>
        <div className='d-flex flex-row justify-content-center align-items-center gap-2'> 
          <NavLink to='/home' 
                   className={`d-flex flex-row justify-content-center align-items-center gap-2 menu-item 
                              ${location.pathname === '/home' ? 'activated-menu-item' :''} `} >
            <IoHome className='icon-color' />
            <div>Home</div>
          </NavLink>
        </div>
        <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
          <NavLink to='/home/projects' 
                   className={`d-flex flex-row justify-content-center align-items-center gap-2 menu-item 
                   ${location.pathname === '/home/projects' ? 'activated-menu-item' :''} `} >
            <MdWork className='icon-color' />
            <div>Projects</div>
          </NavLink>
        </div>
        <div className='d-flex flex-row justify-content-center align-items-center gap-2 '>
          <NavLink to='/home/about-me'
                   className={`d-flex flex-row justify-content-center align-items-center gap-2 menu-item 
                   ${location.pathname === '/home/about-me' ? 'activated-menu-item' :''} `} >
            <RiProfileLine className='icon-color' />
            <div>About Me</div>
          </NavLink>
        </div>
        <div className='d-flex flex-row justify-content-center align-items-center gap-2 '>
          <a  href={pdf} download="Vamsi Vemulapalli Full Stack Developer"
              className={`d-flex flex-row justify-content-center align-items-center gap-2 menu-item`} >
            <IoMdDownload className='icon-color' />
            <div>Resume</div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header