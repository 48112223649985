import React from 'react';

import vv from '../assets/images/vv.jpg';

function AboutMe() {
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex flex-column flex-sm-row justify-content-center align-items-center' >
        <div className='d-flex flex-column justify-content-center align-items-center p-4'>
            <span style={{fontSize:"1.65rem"}}>Hello!</span>
            <span>I'm <span className='type-writer' style={{fontSize:"2rem"}}>Vamsi Vemulapalli</span></span>
            <span style={{fontSize:"1.65rem"}}>MEAN /MERN Stack Developer</span>
        </div>
        <div className='d-flex flex-column'>
          <div className='d-flex flex-column justify-content-center banner-logo-section'>
            <div className='banner-logo-container justify-content-center'>
              <img className='banner-logo' src={vv} alt={`Vamsi Vemulapalli`} />
              <div className='skill-logo' style={{"--position":"1"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-plain-wordmark.svg" alt='HTML5' />
              </div>
              <div className='skill-logo' style={{"--position":"2"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-plain-wordmark.svg" alt='CSS3' />
              </div>
              <div className='skill-logo' style={{"--position":"3"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg" alt='JS' />
              </div>
              <div className='skill-logo' style={{"--position":"4"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/sass/sass-original.svg" alt='SCSS' />
              </div>
              <div className='skill-logo' style={{"--position":"5"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/angular/angular-original.svg" alt='Angular' />
              </div>
              <div className='skill-logo' style={{"--position":"6"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original-wordmark.svg" alt='React' />
              </div>
              <div className='skill-logo' style={{"--position":"7"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-plain-wordmark.svg" alt='Nodejs' />
              </div>
              <div className='skill-logo' style={{"--position":"8"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/sqldeveloper/sqldeveloper-original.svg" alt='SQL' />
              </div>
              <div className='skill-logo' style={{"--position":"9"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mongodb/mongodb-original-wordmark.svg" alt='MongoDB' />
              </div>
              <div className='skill-logo' style={{"--position":"10"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/docker/docker-original-wordmark.svg"  alt='Docker'/>
              </div>
              <div className='skill-logo' style={{"--position":"11"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/kubernetes/kubernetes-original-wordmark.svg" alt='Kubernetes' />
              </div>
              <div className='skill-logo' style={{"--position":"12"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/jenkins/jenkins-original.svg" alt='Jenkins' />
              </div>
              <div className='skill-logo' style={{"--position":"13"}}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/amazonwebservices/amazonwebservices-original-wordmark.svg" alt='AWS' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column justify-content-center about-me-content'>
        <span>I'm a passionate <b>MEAN/MERN</b> developer dedicated to crafting exceptional user experiences through intuitive and visually stunning interfaces. 
        With a blend of creativity and technical expertise, I specialize in <b>translating ideas into engaging digital products</b> that resonate with users. 
        <br />
        <br />
        My approach to UI development revolves around putting the user at the forefront of every design decision. I believe that seamless functionality should always be accompanied by captivating aesthetics, resulting in interfaces that not only meet users' needs but also delight and inspire them. Throughout my career, I've had the privilege of working on a diverse range of projects, from responsive web applications to mobile apps and beyond. Each project presents a unique set of challenges, and I thrive on finding innovative solutions that push the boundaries of design and technology.
        <br />
        <br />
        In my portfolio, you'll find a curated selection of my work, showcasing my skills in UI design, front-end development, and usability optimization. Whether it's creating pixel-perfect layouts, implementing smooth animations, or ensuring accessibility for all users, I'm committed to delivering excellence in every project I undertake.  I'm constantly seeking new opportunities to collaborate with like-minded individuals and organizations who share my passion for creating exceptional digital experiences. If you're looking for a UI developer who can bring your vision to life and elevate your digital presence, I'd love to hear from you.
        <br />
        <br />
        Thank you for visiting my portfolio, and I hope you enjoy exploring my work!</span>
      </div>
    </div>
  )
}

export default AboutMe
