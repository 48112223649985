import React from 'react'
import { IoHome } from 'react-icons/io5'
import { MdWork } from 'react-icons/md'
import { RiProfileLine } from 'react-icons/ri';
import { NavLink,useLocation } from 'react-router-dom'

function Footer() {

    const location = useLocation();

  return (
    <div>
      <div className='col d-flex flex-row justify-content-center align-items-center vv-flex-gap-20'>
        <div className='d-flex flex-row justify-content-center align-items-center gap-2'> 
          <NavLink to='/home' 
                   className={`d-flex flex-column justify-content-center align-items-center menu-item menu-item-footer
                              ${location.pathname === '/home' ? 'activated-menu-item' :''} `} >
            <IoHome />
            <span>Home</span>
          </NavLink>
        </div>
        <div className='d-flex flex-row justify-content-center align-items-center gap-2'>
          <NavLink to='/home/projects' 
                   className={`d-flex flex-column justify-content-center align-items-center menu-item  menu-item-footer
                   ${location.pathname === '/home/projects' ? 'activated-menu-item' :''} `} >
            <MdWork />
            <span>Projects</span>
          </NavLink>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <NavLink to='/home/about-me'
                   className={`d-flex flex-column justify-content-center align-items-center menu-item menu-item-footer
                   ${location.pathname === '/home/about-me' ? 'activated-menu-item' :''} `} >
            <RiProfileLine />
            <span>About Me</span>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default Footer
