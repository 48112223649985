import './App.scss';
import Routing from './Routing';


function App() {
  return (
    <div className="App d-flex flex-grow-1 flex-column">
      <Routing /> 
    </div>
  );
}

export default App;
